import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "single-filter"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")

  return (_ctx.singleFilter)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.showCheckboxes ? 'el-checkbox-group' : 'div'), {
          class: "single-filter",
          modelValue: _ctx.filterData.values,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.filterData.values = $event))
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.singleFilter.options, (option) => {
              return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                key: option.value
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.showCheckboxes ? 'el-checkbox' : 'div'), {
                    style: { width: '100%' },
                    class: "filter-option",
                    label: option.value,
                    onClick: ($event: any) => (_ctx.changeValue($event, option.value))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(option.text), 1)
                    ]),
                    _: 2
                  }, 1032, ["label", "onClick"]))
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"]))
      ]))
    : _createCommentVNode("", true)
}