import { computed, defineComponent, reactive, watch } from "vue";
import SingleFilter from "./SingleFilter/SingleFilter.vue";
import GroupedFilter from "./GroupedFilter/GroupedFilter.vue";

export interface FilterOption {
  text: string;
  value: any;
  data?: any;
}

export interface FilterItem {
  header?: string;
  options: FilterOption[];
}

export interface FilterObject {
  items: FilterItem[];
  values: any[] | any;
}

export default defineComponent({
  name: "DropdownFilter",
  props: {
    filterData: { type: Object as () => FilterObject },
  },
  components: { SingleFilter, GroupedFilter },
  setup(props) {
    return {
      singleFilter: computed(() =>
        props.filterData?.items.length === 1 &&
        !props.filterData?.items[0].header
          ? props.filterData?.items[0]
          : null
      ),
    };
  },
});
