import { computed, defineComponent, reactive, watch } from "vue";
import SearchInput from "@/components/SearchInput/SearchInput.vue";
import { FilterItem, FilterObject } from "../DropdownFilter";
import useCheckboxSwitch from "../useCheckboxSwitch";

export default defineComponent({
  name: "HeaderFilter",
  props: {
    filterData: { type: Object as () => FilterObject },
    showEmptyGroups: Boolean,
  },
  components: { SearchInput },
  setup(props) {
    const state = reactive({
      search: "",
      checkAll: false,
      isIndeterminate: false,
      openedFilters: props.filterData?.items
        .map((i) => i.header)
        .filter((h) => h),
    });

    watch(
      props.filterData as FilterObject,
      (newValue: FilterObject, oldValue: FilterObject) => {
        state.openedFilters = props.filterData?.items
          .map((i) => i.header)
          .filter((h) => h);
      }
    );

    const searchedData = computed(() =>
      (state.search
        ? props.filterData?.items
            ?.map((f: FilterItem) => ({
              ...f,
              options: f.options.filter((o) =>
                o.text.toLowerCase().includes(state.search.toLowerCase())
              ),
            }))
            .filter((f) => f.options.length)
        : props.filterData?.items
      )?.filter((i) => i.options.length || props.showEmptyGroups)
    );

    const allOptions = computed(() =>
      props.filterData?.items
        ?.map((f) => f.options)
        .reduce((acc, cur) => [...acc, ...cur], [])
    );

    const totalOptions = computed(() => allOptions.value?.length || 0);

    return {
      state,
      handleCheckedChange(value: any[]) {
        const checkedCount = value.length;
        state.checkAll = checkedCount === totalOptions.value;
        state.isIndeterminate =
          checkedCount > 0 && checkedCount < totalOptions.value;
      },
      handleCheckAllChange(val: boolean) {
        if (props.filterData?.values) {
          // eslint-disable-next-line vue/no-mutating-props
          props.filterData.values = val
            ? allOptions.value?.map((o) => o.value) || []
            : [];
          state.isIndeterminate = false;
          state.checkAll = val;
        }
      },
      searchedData,
      allOptions,
      totalOptions,
      ...useCheckboxSwitch(props.filterData as FilterObject),
    };
  },
});
