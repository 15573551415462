import { computed, defineComponent } from "vue";
import { FilterObject } from "../DropdownFilter";
import useCheckboxSwitch from "../useCheckboxSwitch";

export default defineComponent({
  name: "HeaderFilter",
  props: {
    filterData: { type: Object as () => FilterObject },
  },
  components: {},
  setup(props) {
    return {
      ...useCheckboxSwitch(props.filterData as FilterObject),
      singleFilter: computed(() => props.filterData?.items[0]),
    };
  },
});
