import { withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "grouped-filter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")
  const _component_el_checkbox = _resolveComponent("el-checkbox")
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")
  const _component_el_collapse = _resolveComponent("el-collapse")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.showCheckboxes)
      ? (_openBlock(), _createBlock("a", {
          key: 0,
          class: "reset-filters-link",
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.handleCheckAllChange(false)), ["prevent","stop"]))
        }, "Reset filters"))
      : _createCommentVNode("", true),
    _createVNode(_component_SearchInput, {
      modelValue: _ctx.state.search,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.state.search = $event))
    }, null, 8, ["modelValue"]),
    (_ctx.showCheckboxes)
      ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
          key: 1,
          class: "check-all-checkbox",
          onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_checkbox, {
              indeterminate: _ctx.state.isIndeterminate,
              modelValue: _ctx.state.checkAll,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.state.checkAll = $event)),
              onChange: _ctx.handleCheckAllChange,
              onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"]))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Select All (" + _toDisplayString(_ctx.totalOptions) + ")", 1)
              ]),
              _: 1
            }, 8, ["indeterminate", "modelValue", "onChange"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_el_collapse, {
      modelValue: _ctx.state.openedFilters,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_ctx.state.openedFilters = $event))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.searchedData, (filter) => {
          return (_openBlock(), _createBlock(_component_el_collapse_item, {
            class: "single-filter",
            title: `${filter.header} (${filter.options.length})`,
            name: filter.header,
            key: filter.header
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.showCheckboxes ? 'el-checkbox-group' : 'div'), {
                class: "single-filter height-auto",
                modelValue: _ctx.filterData.values,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.filterData.values = $event)),
                onChange: _ctx.handleCheckedChange
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(filter.options, (option) => {
                    return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                      key: option.value,
                      onClick: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["stop"]))
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.showCheckboxes ? 'el-checkbox' : 'div'), {
                          class: "filter-option",
                          label: option.value,
                          style: { width: '100%' },
                          onClick: ($event: any) => (_ctx.changeValue($event, option.value))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(option.text), 1)
                          ]),
                          _: 2
                        }, 1032, ["label", "onClick"]))
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["modelValue", "onChange"]))
            ]),
            _: 2
          }, 1032, ["title", "name"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}