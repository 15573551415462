import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SingleFilter = _resolveComponent("SingleFilter")
  const _component_GroupedFilter = _resolveComponent("GroupedFilter")

  return (_ctx.singleFilter)
    ? (_openBlock(), _createBlock(_component_SingleFilter, {
        key: 0,
        filterData: _ctx.filterData
      }, null, 8, ["filterData"]))
    : (_openBlock(), _createBlock(_component_GroupedFilter, {
        key: 1,
        filterData: _ctx.filterData,
        showEmptyGroups: _ctx.showEmptyGroups
      }, null, 8, ["filterData", "showEmptyGroups"]))
}