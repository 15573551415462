import { ref, computed } from "vue";
import isArray from "lodash-es/isArray";

import { FilterObject } from "./DropdownFilter";

export default function(filter: FilterObject) {
  const showCheckboxes = computed(() => isArray(filter.values));
  return {
    showCheckboxes,
    changeValue: (event: Event, value: any) => {
      if (!showCheckboxes.value) {
        filter.values = value;
      } else {
        event.stopPropagation();
      }
    },
  };
}
