import { supportEmail } from "@/constants";
import { defineComponent } from "vue";

import SearchIcon from "@/assets/icons/Search.vue";
import debounce from "lodash-es/debounce";

export default defineComponent({
  name: "SearchInput",
  props: {
    debounceTime: { type: Number, default: 500 },
  },
  components: { SearchIcon },
  setup(props, { emit }) {
    return {
      supportEmail,
      inputSearchValue: debounce((value: string) => {
        emit("debounce-input");
      }, props.debounceTime),
    };
  },
});
