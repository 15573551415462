import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchIcon = _resolveComponent("SearchIcon")
  const _component_el_input = _resolveComponent("el-input")

  return (_openBlock(), _createBlock(_component_el_input, {
    class: "search-input",
    placeholder: "Search",
    onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.inputSearchValue($event))),
    "data-e2e": "subjectSearch"
  }, {
    prefix: _withCtx(() => [
      _createVNode(_component_SearchIcon)
    ]),
    _: 1
  }))
}