<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <path
        id="e4byve52ba"
        d="M12.5 11h-.79l-.28-.27C12.41 9.59 13 8.11 13 6.5 13 2.91 10.09 0 6.5 0S0 2.91 0 6.5 2.91 13 6.5 13c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L17.49 16l-4.99-5zm-6 0C4.01 11 2 8.99 2 6.5S4.01 2 6.5 2 11 4.01 11 6.5 8.99 11 6.5 11z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <path
              d="M0 0L24 0 24 24 0 24z"
              transform="translate(-113 -104) translate(97 96) translate(16 8)"
            />
            <g
              transform="translate(-113 -104) translate(97 96) translate(16 8) translate(3 3)"
            >
              <mask id="ac6n7rg0sb" fill="#fff">
                <use xlink:href="#e4byve52ba" />
              </mask>
              <use fill="#000" fill-rule="nonzero" xlink:href="#e4byve52ba" />
              <g fill="#90A4AE" mask="url(#ac6n7rg0sb)">
                <path d="M0 0H24V24H0z" transform="translate(-3 -3)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
